.searchLayout {
  background: whitesmoke;
  display: flex;
  top: 0;
  position: sticky;
  z-index: 2;
  min-height: 0;
}

.generatedLabels {
  position: absolute;
  display: flex;
}

.selectedLabels {
  position: absolute;
  display: flex;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Set the container to at least the viewport height */
  margin: 0;
  padding: 0;
}

.content {
  max-width: '100%';
  margin: 0 !important;
  padding: 0 !important;
}

.contentPaper {
  padding: 20
}

.footer {
  margin-top: auto;
  /* Push the footer to the bottom */
}


/* ======= SIDENAV CSS ======= */

/* Adjusting the CSS to match Material-UI breakpoints */

.responsive-sidebar {
  width: 10%;
  /* Default width for extra small devices (xs) */
}

@media (min-width: 600px) {

  /* 'sm' breakpoint */
  .responsive-sidebar {
    width: 10%;
    /* Your specified width for 'sm' and above */
  }
}

@media (min-width: 801px) {

  /* 'md' breakpoint */
  .responsive-sidebar {
    width: 25%;
    /* Adjusted to include 'md' breakpoint, based on your original pattern */
  }
}

@media (min-width: 1200px) {

  /* 'lg' breakpoint - was missing */
  .responsive-sidebar {
    width: 20%;
    /* Example width for 'lg', you can adjust this as needed */
  }
}

@media (min-width: 1536px) {

  /* 'xl' breakpoint */
  .responsive-sidebar {
    width: 13%;
    /* Your specified width for 'xl' */
  }
}


/* ======= MAIN COMPONENT LIST CSS ======= */

/* Adjusting the CSS to match Material-UI breakpoints */

.main-content {
  margin-top: 60px;
  width: 90%;
  /* Default width for extra small devices (xs) */
}

@media (min-width: 600px) {

  /* 'sm' breakpoint */
  .main-content {
    width: 90%;
    /* Adjusted to match your requirement */
  }
}

@media (min-width: 960px) {

  /* 'md' breakpoint */
  .main-content {
    width: 75%;
    /* Your custom width for 'md' and up */
  }
}

@media (min-width: 1200px) {

  /* 'lg' breakpoint - was missing */
  .main-content {
    width: 80%;
    /* Example width for 'lg', adjust as needed */
  }
}

@media (min-width: 1536px) {

  /* 'xl' breakpoint */
  .main-content {
    width: 87%;
    /* Your specified width for 'xl' */
  }

}


.burger-menu {
  display: 'none',

}

@media (max-width: 800px) {
  .burger-menu {
    display: 'block',
  
  }}


  /* ======= APP FONTS FROM FUTURA FAMILY ======= */

  @font-face {
    font-family: 'Futura-Bold';
    src: local('Futura-Bold'), url('../../public/fonts/futura-pt_bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Futura-Book';
    src: local('Futura-Book'), url('../../public/fonts/futura-pt_book.ttf') format('truetype');
  }