.style-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px !important;
  border: 1px solid #000;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.4);
}

.style-modal-box {
  height: 200px;
  overflow-y: auto;
  padding: 10px !important;
}

.style-modal-stack {
  display: flex !important;
}


/* Adjust css for print media */
@media print {

  html,
  body {
    height: 100% !important; 
    overflow: hidden !important;
    visibility: hidden !important;
  }

  .style-modal {
    visibility: visible;
    position: fixed;
    width: auto; 
    height: auto;
    transform: translate(-50%, -50%) scale(0.7); 
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    page-break-inside: avoid !important; 
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }

  .style-modal-box {
    height: auto;
    overflow-y: auto;
    overflow: visible;
    padding: 10px !important;
  }

  .style-modal-stack {
    display: none !important;
  }

}
